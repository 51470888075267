import { render, staticRenderFns } from "./Wirksamkeitspruefung.vue?vue&type=template&id=78fb5dba&scoped=true&"
import script from "./Wirksamkeitspruefung.vue?vue&type=script&lang=js&"
export * from "./Wirksamkeitspruefung.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78fb5dba",
  null
  
)

export default component.exports