<template>
    <div>
        <MeldungsvorlageElementRight
            :vorlage_name_in="this.$parent.vorlage_name_in"
            vorlage_menu_title="QR-Code/URL">
        </MeldungsvorlageElementRight>

        <div class="chat-body d-flex justify-content-center">
            <div class="col-md-11">
                <div class="mb-30">
                    <MeldungsvorlagenRightElementLink
                        :api_einstellungen_meldungsvorlagen_link_show_in="this.$parent.api_einstellungen_meldungsvorlagen_link_show_in"
                        :api_einstellungen_meldungsvorlagen_link_store_in="this.$parent.api_einstellungen_meldungsvorlagen_link_store_in">
                    </MeldungsvorlagenRightElementLink>
                </div>
                <br><br><br>
            </div>
        </div>
    </div>
</template>

<script>

// ***********  Localization ***************
import Lang from 'lang.js'

// get the data source
import translations from '../../../../../vue-translations.js';

import MeldungsvorlageElementRight from '../components/EMelderComponentEinstellungenMeldungsvorlagenRightElement';

import MeldungsvorlagenRightElementLink
    from '../components/EMelderComponentEinstellungenMeldungsvorlagenRightElementLink';

//Bsp.: lang.get('auth.btn_login'));

// ***********  Localization ***************

export default {
    props: [],
    components: {MeldungsvorlageElementRight, MeldungsvorlagenRightElementLink},
    data() {
        return {
            lang: null,
        };
    },
    created() {
        var lang = new Lang();

        lang.setLocale(this.locale_in);

        if (lang.getLocale() === 'undefined') {
            lang.setLocale('de');
        }
        lang.setMessages(translations);
        this.lang = lang;
    },
    mounted() {
    },
    watch: {},
    computed: {},
    methods: {
        getData() {
        },
    }
};
</script>
<style scoped></style>
