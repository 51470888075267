<template>
    <div>
        <div class="tree-container">
            <treeselect
                :name="name_input"
                :multiple="multiple"
                :clearable="clearable"
                :searchable="searchable"
                :searchNested="searchNested"
                :async="async"
                :disabled="disabled"
                :open-on-click="openOnClick"
                :open-on-focus="openOnFocus"
                :clear-on-select="clearOnSelect"
                :close-on-select="closeOnSelect"
                :always-open="alwaysOpen"
                :defaultExpandLevel="defaultExpandLevel"
                :append-to-body="appendToBody"
                :options="options"
                :value-consists-of="valueConsistsOf"
                :loading="loading"
                :auto-load-root-options="auto_load_root_options"
                :load-options="loadOptions"
                :show-count="showCount"
                :showCountOf="showCountOf"
                :loadingText="loadingText"
                :noChildrenText="noChildrenText"
                :noOptionsText="noOptionsText"
                :noResultsText="noResultsText"
                :openDirection="openDirection"
                :placeholder="placeholder"
                :required="required"
                :limit="limit"
                :max-height="maxHeight"
                :disableFuzzyMatching="disableFuzzyMatching"
                v-model="value"
                :normalizer="normalizer"
                @select="onSelect"
                @deselect="onDeselect"
                @input="onInput"
            />
        </div>
    </div>
</template>

<script>

    // import the component
    import Treeselect from '@riophae/vue-treeselect'
    import {LOAD_CHILDREN_OPTIONS} from '@riophae/vue-treeselect';
    import {LOAD_ROOT_OPTIONS} from '@riophae/vue-treeselect'
    // import the styles
    import '@riophae/vue-treeselect/dist/vue-treeselect.css'

    export default {
        props: ['name_input', 'options_input', 'value_input', 'include_ids_input', 'exclude_ids_input', 'exclude_own_switch', 'multi_switch', 'api_url', 'max_height_in', 'limit_in','value_consists_of_in','placeholder_in','node_label_in','defaultExpandLevel_in'],
        components: {Treeselect},
        data() {
            return {

                // Switch Parameter
                exclude_own: Boolean(this.exclude_own_switch),
                multiple: Boolean(this.multi_switch),
                disableFuzzyMatching: true,
                include_ids: null,
                exclude_ids: null,
                clearable: true,
                searchable: true,
                searchNested: false,
                async: false,
                disabled: false,
                openOnClick: true,
                openOnFocus: false,
                clearOnSelect: true,
                closeOnSelect: false,
                alwaysOpen: true,
                appendToBody: false,
                defaultExpandLevel: this.defaultExpandLevel_in ? this.defaultExpandLevel_in : 0,
                valueConsistsOf: this.value_consists_of_in ? this.value_consists_of_in : 'LEAF_PRIORITY' ,
                loadingText: 'Lade...',
                noChildrenText: 'Keine Mitarbeiter zugeordnet',
                noOptionsText: 'Keine Daten',
                noResultsText: 'Keine Daten',
                openDirection: 'below',
                placeholder: this.placeholder_in ? this.placeholder_in : 'Bitte wählen Sie Ihre Mitarbeiter aus',
                auto_load_root_options: false,
                loading: true,
                required: false,
                maxHeight: Number(this.max_height_in),
                limit: Number(this.limit_in),
                showCount: false,
                showCountOf: 'ALL_CHILDREN',
                value: null,
                options: null,
                node_label: this.node_label_in ? this.node_label_in : 'name',
            }
        },
        mounted() {

            let self = this;
            setTimeout(function(){ self.parseInputs(); }, 600);

        },
        methods: {

            checkForStrings: function (event) {
                Object.keys(this.value).forEach(key => {
                    if (typeof this.value[key] === 'string' || this.value[key] instanceof String) {
                        this.value.splice(key, 1);
                    }
                })
            },
            parseInputs: function () {

                this.include_ids = JSON.parse(this.include_ids_input);

                if (this.include_ids) {
                    Object.keys(this.include_ids).forEach(key => {
                        this.include_ids[key] = parseInt(this.include_ids[key]);
                    });
                }

                this.exclude_ids = JSON.parse(this.exclude_ids_input);

                if (this.exclude_ids) {
                    Object.keys(this.exclude_ids).forEach(key => {
                        this.exclude_ids[key] = parseInt(this.exclude_ids[key]);
                    });
                }

                if ( JSON.parse(this.value_input).length > 0) {
                    this.value = JSON.parse(this.value_input);

                    if (this.value) {
                        Object.keys(this.value).forEach(key => {
                            this.value[key] = parseInt(this.value[key]);
                        });
                    }
                }

            },
            onSelect(node, id) {
                //console.log(node);
            },
            onDeselect(node, id) {
                //console.log(node);
            },
            onInput(value, id) {
                this.$eventBus.$emit('emelder_meldungsvorlage:change_treeselect', {
                    name: this.name_input,
                    values: value,
                    id: id
                })
            },
            loadOptions({action, parentNode, callback}) {

                if (action === LOAD_ROOT_OPTIONS) {
                    window.axios.post(this.api_url, {
                        'include_ids': this.include_ids_input,
                        'exclude_ids': this.exclude_ids_input,
                        'exclude_own': this.exclude_own,
                    }).then(({data}) => {
                        //console.log(data);
                        this.options = data;
                        callback()
                    });
                }
            },
            normalizer(node) {
                return {
                    id: node.id,
                    label: node[this.node_label],
                    //children: node.subOptions,
                }
            },
        },
    }
</script>

<style>

    .tree-container {
        height: auto;
    }

    .vue-treeselect__menu {
        height: auto;
    }

</style>
