<template>
    <div>
        <div class="hk-pg-header align-items-top ml-20" style="margin-bottom: 0px !important;">
            <div>
                <h2 class="hk-pg-title font-weight-600 mt-15 mb-10">{{vorlage_name_in}} bearbeiten</h2>
                <p>Sie haben Fragen zum Thema Meldungsvorlagen? <a
                    href="/support">Hilfe</a></p>
            </div>
        </div>
        <header class="mb-30">
            <div class="d-flex">
                <div class="emelder_status emelder_einstellungen_icon">
                    <i class="font-30 mr-10 text-light-40 zmdi zmdi-edit emelder_einstellungen_icon_symbol"></i>
                </div>
                <span class="text-dark ml-15 mt-10"><h5>{{vorlage_menu_title}}</h5></span>
                <br>
            </div>
            <div class="mr-30"></div>
        </header>
        <!--<footer>
            <div class="text-right ml-100">
            Copyright <a href="https://www.jagals.de/" class="text-dark ml-5 mr-5" target="_blank">intralean medical
            GmbH</a> ©
            2020</div>
        </footer>-->
    </div>
</template>

<script>

    // ***********  Localization ***************
    import Lang from 'lang.js'

    // get the data source
    import translations from '../../../../../vue-translations.js';

    //Bsp.: lang.get('auth.btn_login'));

    // ***********  Localization ***************

    export default {
        props: ['vorlage_name_in', 'vorlage_menu_title', 'meldungskategorien_in', 'page_in'],
        components: {},
        data() {
            return {
                lang: null,
            };
        },
        created() {
            var lang = new Lang();

            lang.setLocale(this.locale_in);

            if (lang.getLocale() === 'undefined') {
                lang.setLocale('de');
            }
            lang.setMessages(translations);
            this.lang = lang;
        },
        mounted() {
        },
        watch: {},
        computed: {},
        methods: {
        }
    };
</script>
<style scoped>
</style>
