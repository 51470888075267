<template>
<div>
    <MeldungsvorlageElementRight
        :vorlage_name_in="this.$parent.vorlage_name_in"
        vorlage_menu_title="Sichtbarkeit">
    </MeldungsvorlageElementRight>

    <div class="chat-body d-flex justify-content-center">
        <div class="col-md-11">
            <div class="mb-30">
               <MeldungsvorlagenRightElementSichtbarkeit
                    :api_einstellungen_meldungsvorlagen_sichtbarkeit_show_in="this.$parent.api_einstellungen_meldungsvorlagen_sichtbarkeit_show_in"
                    :api_einstellungen_meldungsvorlagen_sichtbarkeit_store_in="this.$parent.api_einstellungen_meldungsvorlagen_sichtbarkeit_store_in"
                    :api_einstellungen_meldungsvorlagen_sichtbarkeit_get_options_in="this.$parent.api_einstellungen_meldungsvorlagen_sichtbarkeit_get_options_in">
                </MeldungsvorlagenRightElementSichtbarkeit>
            </div>
            <br><br><br>
        </div>
    </div>
    </div>
</template>

<script>

    // ***********  Localization ***************
    import Lang from 'lang.js'

    // get the data source
    import translations from '../../../../../vue-translations.js';

    import MeldungsvorlageElementRight from '../components/EMelderComponentEinstellungenMeldungsvorlagenRightElement';
    import MeldungsvorlagenRightElementSichtbarkeit
        from '../components/EMelderComponentEinstellungenMeldungsvorlagenRightElementSichtbarkeit';

    //Bsp.: lang.get('auth.btn_login'));

    // ***********  Localization ***************

    export default {
        props: [],
        components: {MeldungsvorlageElementRight, MeldungsvorlagenRightElementSichtbarkeit},
        data() {
            return {
                lang: null,
            };
        },
        created() {
            var lang = new Lang();

            lang.setLocale(this.locale_in);

            if (lang.getLocale() === 'undefined') {
                lang.setLocale('de');
            }
            lang.setMessages(translations);
            this.lang = lang;
        },
        mounted() {
        },
        watch: {},
        computed: {},
        methods: {
            getData() {
            },
        }
    };
</script>
<style scoped>
    .right_page {
        width: 100%;
    }

    .chat-body {
        height: calc(100vh - 458px) !important;
    }
</style>
