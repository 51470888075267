<template>
    <div class="chatapp-left">
        <header>
            <a href="javascript:void(0)" class="chat-more">
                <i class="zmdi zmdi-menu font-20 mr-10 text-light-40"></i>
            </a>
            <span class="">Menu</span>
            <a href="javascript:void(0)" class="chat-compose">
            </a>
        </header>
        <div class="chatapp-users-list">
            <div class="nicescroll-bar">
                <!-- <div class="d-inline-flex menu-top">
                     <div class="d-flex menu-top-item">
                         <a href="javascript:void(0);" class="media">
                             <div class="media-img-wrap text-center mr-auto ml-auto justifiy-content-center">
                                 <i class="font-30 mr-10 text-light-40 zmdi zmdi-assignment"></i>
                             </div>
                         </a>
                     </div>
                     <div class="d-flex menu-top-item">
                         <a href="javascript:void(0);" class="media">
                             <div class="media-img-wrap mr-auto ml-auto">
                                 <i class="font-30 mr-10 text-light-40 zmdi zmdi-border-top"></i>
                             </div>
                         </a>
                     </div>
                     <div class="d-flex menu-top-item">
                         <a href="javascript:void(0);" class="media">
                             <div class="media-img-wrap mr-auto ml-auto">
                                 <i class="font-30 mr-10 text-light-40 zmdi zmdi-border-top"></i>
                             </div>
                         </a>
                     </div>
                     <div class="d-flex menu-top-item">
                         <a href="javascript:void(0);" class="media">
                             <div class="media-img-wrap mr-auto ml-auto">
                                 <i class="font-30 mr-10 text-light-40 zmdi zmdi-border-top"></i>
                             </div>
                         </a>
                     </div>
                 </div>-->
                <div class="chat-hr-wrap">
                    <hr>
                </div>
                <div v-for="item in items" :key="item.id">
                    <MeldungsvorlageElementLeftItem
                        :id="item.id"
                        :bezeichnung="item.bezeichnung"
                        :link="item.link"
                        :icon="item.icon"
                        :highlight="item.hightlight"
                        :active="item.active"
                    >
                    </MeldungsvorlageElementLeftItem>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    // ***********  Localization ***************
    import Lang from 'lang.js'

    // get the data source
    import translations from '../../../../../vue-translations.js';


    //Bsp.: lang.get('auth.btn_login'));

    import MeldungsvorlageElementLeftItem from './EMelderComponentEinstellungenMeldungsvorlagenLeftElementItem';

    // ***********  Localization ***************

    export default {
        props: [],
        components: {MeldungsvorlageElementLeftItem},
        data() {
            return {
                items: [
                    {
                        id: 1,
                        bezeichnung: 'Layout',
                        link: 'layout',
                        icon: 'zmdi-assignment',
                        hightlight: false,
                        active: false,
                    },
                    {
                        id: 2,
                        bezeichnung: 'Kopfzeile',
                        link: 'kopfzeile',
                        icon: 'zmdi-border-top',
                        hightlight: false,
                        active: false,
                    },
                    {
                        id: 3,
                        bezeichnung: 'Fußzeile',
                        link: 'fußzeile',
                        icon: 'zmdi-border-bottom',
                        hightlight: false,
                        active: false,
                    },
                    {
                        id: 4,
                        bezeichnung: 'Aufgaben & Personen',
                        link: 'aufgaben',
                        icon: 'zmdi-assignment-account',
                        hightlight: false,
                        active: false,
                    },
                    {
                        id: 5,
                        bezeichnung: 'Sichtbarkeit',
                        link: 'sichtbarkeit',
                        icon: 'zmdi-lock',
                        hightlight: false,
                        active: false,
                    },
                    {
                        id: 6,
                        bezeichnung: 'Anonymisieren',
                        link:'anonymisieren',
                        icon: 'zmdi-shield-security',
                        hightlight: false,
                        active: false,
                    },
                    {
                        id: 7,
                        bezeichnung: 'Risikobewertung',
                        link:'risikobewertung',
                        icon: 'zmdi-traffic',
                        hightlight: false,
                        active: false,
                    },
                    {
                        id: 8,
                        bezeichnung: 'Wirksamkeitsprüfung',
                        link: 'wirksamkeitsprüfung',
                        icon: 'zmdi-check-circle',
                        hightlight: false,
                        active: false,
                    },
                    {
                        id: 9,
                        bezeichnung: 'Vorschau',
                        link: 'vorschau',
                        icon: 'zmdi-open-in-browser',
                        hightlight: true,
                        active: false,
                    },
                    {
                        id: 10,
                        bezeichnung: 'QR-Code/URL',
                        link: 'link',
                        icon: 'zmdi-link',
                        hightlight: false,
                        active: false,
                    }
                ],
                lang: null,
            }
        },
        created() {
            var lang = new Lang();

            lang.setLocale(this.locale_in);

            if (lang.getLocale() === 'undefined') {
                lang.setLocale('de');
            }
            lang.setMessages(translations);
            this.lang = lang;

            this.setItemActive(this.$route.name);
        }
        ,
        mounted() {},
        watch: {
            $route(to, from) {
               this.setItemActive(to.name);
               this.setItemInactive(from.name);
            }
        },
        computed: {},
        methods: {
            setItemActive(itemName) {
                let obj_index = this.items.filter(function(item) {
                    if(item.link == itemName) return item;
                });
                this.items[(obj_index[0].id - 1)].active = true;
            },
            setItemInactive(itemName) {
                let obj_index = this.items.filter(function(item) {
                    if(item.link == itemName) return item;
                });
                this.items[(obj_index[0].id - 1)].active = false;
            },
        }
    }
    ;
</script>
<style scoped>
    .chatapp-left {
        -webkit-box-flex: 0;
        flex: 0 0 15% !important;
        max-width: 15% !important;
        border-right: 1px solid #eaecec;
        position: relative;
        z-index: 4;
        left: 0;
        height: 100%;
        background-color: #edf7fe;
    }

    .menu-top {
        display: flex;
        flex-wrap: wrap;
        width: 100% !important;
    }

    .menu-top-item {
        flex-grow: 1;
        min-width: 25%;
        border-right: 1px solid #eaecec;
        background-color: #e9ecef;
    }
</style>
