<template>
    <div>
        <div class="chatapp-wrap">
            <MeldungsvorlageElementLeft>
            </MeldungsvorlageElementLeft>

            <div class="chatapp-right">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>
<script>

    import Vue from 'vue'

    // ***********  Localization ***************
    import Lang from 'lang.js'

    // get the data source
    import translations from '../../../../vue-translations.js';

    import MeldungsvorlageElementLeft from './components/EMelderComponentEinstellungenMeldungsvorlagenLeftElement';
    import MeldungsvorlageElementRight from './components/EMelderComponentEinstellungenMeldungsvorlagenRightElement';

    //Bsp.: lang.get('auth.btn_login'));

    // ***********  Localization ***************

    export default {
        props: ['vorlage_name_in','api_einstellungen_meldungsvorlagen_layout_get_baustein_typen_in','api_einstellungen_meldungsvorlagen_layout_get_auswahllisten_in','api_einstellungen_meldungsvorlagen_layout_get_auswahlliste_in','api_einstellungen_meldungsvorlagen_layout_get_auswahlskalen_in','api_einstellungen_meldungsvorlagen_layout_get_auswahlskala_in','api_einstellungen_meldungsvorlagen_layout_show_in','api_einstellungen_meldungsvorlagen_layout_store_in','api_einstellungen_meldungsvorlagen_layout_add_zeile_in','api_einstellungen_meldungsvorlagen_layout_remove_zeile_in','api_einstellungen_meldungsvorlagen_layout_store_spalte_in','api_einstellungen_meldungsvorlagen_layout_add_spalte_in','api_einstellungen_meldungsvorlagen_layout_remove_spalte_in','api_einstellungen_meldungsvorlagen_kopfzeile_show_in','api_einstellungen_meldungsvorlagen_kopfzeile_store_in','api_einstellungen_meldungsvorlagen_fusszeile_show_in','api_einstellungen_meldungsvorlagen_fusszeile_store_in','api_einstellungen_meldungsvorlagen_aufgaben_show_in','api_einstellungen_meldungsvorlagen_aufgaben_store_in','api_einstellungen_meldungsvorlagen_aufgaben_get_verantwortliche_in','api_einstellungen_meldungsvorlagen_sichtbarkeit_show_in','api_einstellungen_meldungsvorlagen_sichtbarkeit_store_in','api_einstellungen_meldungsvorlagen_sichtbarkeit_get_options_in','api_einstellungen_meldungsvorlagen_wirksamkeitspruefung_show_in','api_einstellungen_meldungsvorlagen_wirksamkeitspruefung_store_in','api_einstellungen_meldungsvorlagen_risikobewertung_show_in','api_einstellungen_meldungsvorlagen_risikobewertung_store_in','api_einstellungen_meldungsvorlagen_anonymisieren_show_in','api_einstellungen_meldungsvorlagen_anonymisieren_store_in','api_einstellungen_meldungsvorlagen_vorschau_show_in','api_get_org_element_gruppen_in','api_get_org_elemente_in','api_einstellungen_meldungsvorlagen_layout_get_kontrollkaestchen_in','api_einstellungen_meldungsvorlagen_link_show_in','api_einstellungen_meldungsvorlagen_link_store_in'],
        components: {MeldungsvorlageElementLeft, MeldungsvorlageElementRight},
        data() {
            return {
                lang: null,
            };
        },
        created() {
            var lang = new Lang();

            lang.setLocale(this.locale_in);

            if (lang.getLocale() === 'undefined') {
                lang.setLocale('de');
            }
            lang.setMessages(translations);
            this.lang = lang;

            // Bereitstellen der API URL
            Vue.prototype.$api_einstellungen_meldungsvorlagen_layout_get_auswahlliste = this.api_einstellungen_meldungsvorlagen_layout_get_auswahlliste_in;
            Vue.prototype.$api_einstellungen_meldungsvorlagen_layout_get_auswahlskala = this.api_einstellungen_meldungsvorlagen_layout_get_auswahlskala_in;
            Vue.prototype.$api_einstellungen_meldungsvorlagen_layout_get_kontrollkaestchen = this.api_einstellungen_meldungsvorlagen_layout_get_kontrollkaestchen_in;
        },
        mounted() {
        },
        watch: {
            // call again the method if the route changes
            //'$route': 'fetchData'
        },
        computed: {},
        methods: {
            getData() {
            },
        }
    };
</script>
<style scoped>

    .chatapp-wrap {
        position: fixed;
        overflow: hidden;
        min-height: calc(100vh - 70px);
        height: auto;
        width: 100%;
        background: #f5f5f6;
    }

    .chatapp-right {
        -webkit-box-flex: 0;
        flex: 0 0 75%;
        max-width: 72%;
        overflow: auto;
        background: #f5f5f6;
        padding-bottom: 35px;
    }

    .chatapp-wrap .chatapp-right footer {
        bottom: 0px;
        height: 35px;
        width: 100%;
        padding: 10px 15px;
        background: #fff;
        border-top: 1px solid #eaecec;
        margin-top: -1px;
        position: fixed;
    }

    .hk-footer-wrap container {
        display: none !important;
    }

    .footer_copyright {
        bottom: -13px;
    }
</style>
